<template>
    <v-dialog :fullscreen="isMobile" v-model="dialog" transition="slide-x-reverse-transition" max-width="500">
        <v-card tile flat>
            <v-app-bar color="white" flat>
                <v-btn icon @click="hide" v-if="isMobile">
                    <v-icon color="black">mdi-arrow-left</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <div class="app-title">
                    Recuperar contraseña
                </div>
                <v-spacer></v-spacer>
                <v-btn icon @click="hide" v-if="!isMobile">
                    <v-icon color="black">mdi-close</v-icon>
                </v-btn>
            </v-app-bar>

            <v-container :class="[{'padding-container': !isMobile}]">
                <v-form v-model="valid">
                    <v-row>
                        <v-col cols="12">
                            <kimsa-text-field
                                label="Email"
                                clearable outlined dense
                                :rules="emailRules"
                                :inputMode="'email'"
                                @change="email = $event"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-btn
                                class="normal-btn"
                                color="primary"
                                depressed block
                                :loading="loading"
                                @click="passwordRecovery"
                            >
                                Recuperar
                            </v-btn>
                            <v-btn
                                class="normal-btn"
                                depressed block text
                                @click="hide"
                            >
                                Cancelar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>

import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import {mapMutations, mapState} from 'vuex'
import Axios from "@/utils/Axios";

export default {
    name: "NewPasswordDialog",
    components: {KimsaTextField},
    data(vm = this) {
        return {
            dialog: false,
            valid: false,
            loading: false,
            email: '',
            emailRules: [
                v => !!v || 'Email obligatorio',
                v => new RegExp(/.+@.+\..+/).test(v) || 'Email invalido'
            ],
        }
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        show() {
            const vm = this
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            vm.reset()
        },
        reset() {
            const vm = this
            vm.loading = false
            vm.email = ''
        },
        async passwordRecovery() {
            const vm = this
            if (!vm.valid) return vm.toast('Email invalido', 'error')
            if (vm.loading) return
            vm.loading = true

            await Axios.post('auth/new-password', {email: vm.email}).then(res => {
                if(res.data.ok) {
                    vm.toast(res.data.message)
                } else {
                    vm.toast(res.data.message, 'error')
                }
            }).catch(error => {
                console.log('error passwordRecovery', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        }
    },
    computed: {
        ...mapState(['dialogActive'])
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
    }
}
</script>

<style scoped>

.padding-container {
    padding: 0 10% 10% 10%;
}

</style>
