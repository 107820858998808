<template>
    <div>
        <span v-if="label" class="label">{{ label }}</span>
        <v-text-field
            :color="color"
            v-model="model"
            :placeholder="placeholder"
            :rules="rules"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            :hide-details="!showDetails"
            :clearable="clearable"
            :solo="solo"
            :dense="dense"
            :flat="flat"
            :outlined="outlined"
            @keydown.enter="$emit('enter')"
            :prepend-inner-icon="icon"
            :readonly="readonly"
            :loading="loading"
        ></v-text-field>
    </div>
</template>

<script>
export default {
    name: "KimsaPasswordField",
    props: {
        label: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'primary'
        },
        rules: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        dense: {
            type: Boolean,
            default: false
        },
        showDetails: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        solo: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            model: '',
            showPassword: false,
        }
    },
    watch: {
        model(val) {
            this.$emit('change', val)
        }
    }
}
</script>

<style scoped>

.label {
    font-size: 15px;
    color: #333333;
}

</style>