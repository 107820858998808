<template>
    <v-card max-width="400px" flat tile>

        <v-form v-model="valid" class="form-card">
            <v-row class="pa-10">
                <v-col cols="12" class="title-welcome py-0" v-if="isLogin">
                    Bienvenido a nuestra plataforma.
                </v-col>
                <v-col cols="12" class="title-form">
                    {{ isLogin ? 'Iniciar Sesión' : 'Cambiar Contraseña' }}
                </v-col>
                <v-col cols="12">
                    <kimsa-text-field
                        label="Email"
                        clearable outlined dense
                        :rules="emailRules"
                        :inputMode="'email'"
                        @change="email = $event"
                    />
                </v-col>
                <v-col cols="12">
                    <kimsa-password-field
                        :label="`Contraseña${!isLogin ? ' Actual' : ''}`"
                        clearable outlined dense
                        :rules="passwordRules"
                        @change="password = $event"
                        @enter="sendData(true)"
                    />
                </v-col>
                <v-col cols="12" v-if="!isLogin">
                    <kimsa-password-field
                        label="Contraseña Nueva"
                        clearable outlined dense
                        :rules="passwordRules"
                        @change="newPassword = $event"
                    />
                </v-col>
                <v-col cols="12" v-if="!isLogin">
                    <kimsa-password-field
                        label="Confirmar Contraseña"
                        clearable outlined dense
                        :rules="confirmPasswordRules"
                        @enter="sendData(false)"
                    />
                </v-col>
                <v-col cols="12">
                    <v-btn color="primary" block depressed @click="sendData(false)" :disabled="!valid"
                           :loading="loading" class="normal-btn">
                        {{ isLogin ? 'Ingresar' : 'Cambiar Contraseña' }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <v-row>
            <v-col cols="12" class="text-center">
                <v-btn color="primary" text v-if="isLogin" class="change-password" @click="goToChangePassword">
                    Cambiar contraseña
                </v-btn>
            </v-col>
            <v-col cols="12" class="text-center pt-0 pb-10">
                <v-btn color="primary" text class="change-password" @click="showRememberPassword">
                    He olvidado mi contraseña
                </v-btn>

                <new-password-dialog ref="remember"/>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import KimsaPasswordField from "@/components/common/inputs/KimsaPasswordField";
import NewPasswordDialog from "@/components/entrance/NewPasswordDialog";

export default {
    name: "UserForm",
    components: {NewPasswordDialog, KimsaPasswordField, KimsaTextField},
    props: {
        isLogin: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            valid: false,
            email: '',
            password: '',
            newPassword: '',
            emailRules: [
                v => !!v || 'Campo requerido',
                v => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(v) || 'Email invalido'
                }
            ],
            passwordRules: [
                v => !!v || 'Campo requerido',
                v => v && v.length >= 6 || 'La contraseña debe ser de minimo 6 caracteres'
            ],
            confirmPasswordRules: [
                v => !!v || 'Campo requerido',
                v => v && v.length >= 6 || 'La contraseña debe ser de minimo 6 caracteres',
                v => v && v === this.newPassword  || 'La contraseñas contraseñas no coinciden'
            ]
        }
    },
    methods: {
        sendData(firstPassword = false) {
            const vm = this

            if(!vm.valid) return
            if(firstPassword && !vm.isLogin) return

            const payload = {
                email: vm.email,
                password: vm.password
            }

            if(!vm.isLogin) payload.newPassword = vm.newPassword

            vm.$emit('send', payload)
        },
        goToChangePassword() {
            const vm = this
            vm.$router.push({name: 'change-password'})
        },
        showRememberPassword() {
            const vm = this
            vm.$refs.remember.show()
        }
    }
}
</script>

<style scoped>

.change-password {
    color: var(--v-primary-base);
    text-transform: none;
    font-weight: normal;
    font-size: 13px;
}

.title-form {
    color: var(--v-primary-base);
    font-weight: bold;
    font-size: 25px;
}

.form-card {
    position: relative;
    bottom: 0;
}

.title-welcome {
    font-family: 'Playfair Display Bold', serif;
    font-weight: bold;
    font-size: 30px;
}

</style>